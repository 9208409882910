import { graphql, useStaticQuery } from "gatsby"

export const useBild = () =>{
  const data = useStaticQuery(graphql`
  query {
    allFile(
      filter: { relativeDirectory: {eq: "nazi"}}
      sort: {order: ASC, fields: base}
    ) {
      edges {
        node {
          base
          childImageSharp {
            
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
              transformOptions: {
                cropFocus: ATTENTION
                fit: CONTAIN
              }
              width:800
              height:650
            )
          }
     
        }
      }
    }
}
`)

return data.allFile
}