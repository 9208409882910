import { graphql, useStaticQuery } from "gatsby"


export const useVerbote = () => {
  const data = useStaticQuery(graphql`
  query{
    allVerboteYaml {
      edges {
        node {
          jahr
          item
          monat
        }
      }
    }
     }
    

  `)

  return data.allVerboteYaml
}