import React,{useEffect,useState} from 'react'
//import Judenverbote from './verbote.json'

import { GatsbyImage } from 'gatsby-plugin-image'
import {Box,Heading,SimpleGrid,Text} from '@chakra-ui/react'
import {motion} from 'framer-motion'


import { useBild } from '../hooks/nazi/use-bild'
import {useVerbote} from '../hooks/verbote/use-verbote'
import MeinSeo from '../components/seo'


const Fenster =({knoten})=> {
  // const colorMode=useColorMode()
   //const fm=colorMode.colorMode
    return(
      <> 
      <motion.div
          initial={{opacity:0,background:'gray.900',paddingBottom:"100px"}}
          animate={{opacity:1}}
          transition={{duration:2,delay:1}}
          >
       <Box   minHeight="380" px="1"  textAlign="left" pt="3">
       
      
      <Text  mt="3" color="gray.600" maxW="770" fontWeight="400" fontSize="3xl"
       pt="2"  letterSpacing="0.19rem">
        <span style={{color:'red'}}>{knoten.node.jahr}</span>{  } {knoten.node.monat}</Text>
       
        <Text  fontSize="md" lineHeight="1.25" fontWeight="400" color="black">
       {knoten.node.item}
       </Text>
       </Box>
    
      </motion.div>
      </>
    )
  }


const delay = (function(){
  let timer = 0
  return function(callback,ms){
    clearTimeout(timer)
    timer= setTimeout(callback,ms)
  }

})()





const Verbote = () => {
   // const [verbote,setVerbote] = useState([])
    const daten = useVerbote()
    const bilder = useBild()
    const [index,setIndex]=useState(0)
    const [bild,setBild] =useState(0)
    //const c = useColorMode()
  
 
    useEffect(()=>{
      let time=5000
     
     if(daten.edges.length<=index){
      setIndex(0)
      setBild(0)
      
    //  }else{
    //   if(index%3===0){
    //     setBild(bild+1)
    //   }
     if(daten.edges[index].node.item.length>200)
       time=10000
     }
     delay(function(){
      if(index<daten.edges.length-1){
      setIndex(index+1)
      if(index%3===0){
        setBild(bild+1)
      }
      }
      else{
        setBild(0)
        setIndex(0)
      }
     },time)
    
    
    return ()=>time
     
   },[daten.edges,index,bild])
   
   
   
    return(
       <Box pt="10" >

         {/* <Box>
         <Text maxWidth="700" fontWeight="500" lineHeight="1.3" fontSize={['xl','2xl']}>„Denn die einen sind im Dunkeln<br/> Und die anderen sind im Licht.<br/> Und man sieht nur die im Lichte. <br/>Die im Dunkeln sieht man nicht.“
      </Text>
      <Text variant="Bild">Berthold Brecht, Dreigroschenoper</Text>
         </Box> */}
     <MeinSeo title="Animation der behördlichen Willkür der Juden" description='Entrechtlichung der Juden. Eine Animation.' />
     <Box>
       <Heading  variant="Titel">Amtliche Verfügungen &amp; Gesetze <br/>gegen die jüdische Bevölkerung</Heading>
     
     <Box bg="gray.100"> 
        <SimpleGrid columns={[1,2]} gap="4">
          
         
             
             <GatsbyImage image={bilder.edges[bild].node.childImageSharp.gatsbyImageData} layout='CONSTRAINED'  alt="Nazi-Bilder"/> 
           
            
 
        
            
           <Box  pt="10" px="3" m="0"> <Fenster knoten={daten.edges[index]}  /></Box> 
             
        </SimpleGrid>
       
        </Box>
       
       <Text px="2" pb="2"  pt="15" fontSize="md" >Bilder aus dem Stadtarchiv Bacharach, zur Verfügung gestellt von Dr. Dagmar Avervano-Schreiber</Text>
       </Box>
       </Box>
    )
}
export default Verbote